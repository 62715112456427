<template>
  <CRow id="silomConnectQRContainer">
    <CCol md="12" lg="12" v-if="$route.name !== 'Dashboard'" id="silomConnectQRMainCol">
      <CCard class="align-items-center shadow-sm" id="silomSmartConnectCard">
        <CCardBody class="mx-auto text-center" id="silomSmartConnectBody">
          <h1 id="silomSmartConnectTitle" class="font-weight-normal mb-2">Silom Smart Connect</h1>
          <h5 class="mb-5" v-html="$t('connectDes')"></h5>
          <hr/>
          <div id="connectInstructions">
            <div v-if="mode === 'development'" id="silomConnectQRDevMode">
              <img id="silomConnectQRDevImage" src="/img/delivery/4.png" style="width: 70%"  />
              <h4 id="applyForSilomConnect">{{  $t('applyForSilomConnect') }}</h4>
              <h5 class="mb-0">1. {{$t('scanQrtoAddLine')}}</h5>
              <a href="https://lin.ee/Y9hUsud" id="silomConnectQRDevLink">
                <img id="qrCodeImage" src="/img/delivery/5.png" style="width: 80%" :alt="$t('addfriend')" />
              </a>
              <h5>2. {{ $t('verifyCodeToUsed') }} </h5>
            </div>
            <div v-else-if="mode === 'production'" id="silomConnectQRProdMode">
              <CImg id="silomConnectQRProdImage" src="/img/delivery/4.png" class="img-fluid " style="width: 70%" />
              <br />
              <a href="https://lin.ee/qXfjxhU">
                <img id="productionQRCodeImage" src="/img/delivery/5.png" style="width: 80%" :alt="$t('addfriend')" />
              </a>
            </div>
          </div>

          <CCard class="text-dark" id="silomConnectQRInfoCard">
            <CCardBody id="silomConnectQRInfoCardBody">
              <div class="col-md-12 mt-1">
                <p v-if="expiredAt == ''"></p>
                <p v-if="expiredAt != ''" id="silomConnectQRYoursMessage">{{ $t('yourVertify') }}</p>
                <p v-else id="silomConnectQRFallbackMessage">{{ $t('verifyfivemin') }}</p>
                <CButton id="generateAuthCodeButton" @click="createAuthCode()" class="btn btn-success" size="lg" v-if="expiredAt == ''">
                  {{ $t('pressCode') }}
                </CButton>
              </div>
              <br v-if="atClick" id="silomConnectQRSpacer" />
              <h1 id="silomConnectQRAuthCode" class="font-weight-normal" >{{ authCode }}</h1>
              <p v-if="expiredAt != ''">
                {{ $t('exCode') }} {{ time }} {{ $t('seconds') }}
              </p>
              <h5 id="refreshAuthCode" v-if="expiredAt != ''" style="color: #3abc98;" @click="createAuthCode()">
                <i class="fi fi-rr-rotate-reverse"></i> {{ $t('refresh') }}
              </h5>
              <p id="sessionExpiredMessage" class="text-danger font-weight-normal mb-3" v-if="isError === true">
                {{ $t('sessionEx') }}
              </p>
            </CCardBody>
          </CCard>
        </CCardBody>
      </CCard>
    </CCol>

    <CModal id="silomConnectQRpopupModal" v-if="$route.name === 'Dashboard'" :show.sync="popupModal" :footer="footer" :closeOnBackdrop="close"
      centered header="false" class="modal-content">
      <div id="silomConnectQRModalContent" class="align-items-center" >
        <div v-if="mode === 'development'" class="text-center">
          <Carousel :perPage="1" :autoplay="true" :loop="true" :adjustableHeight="true" paginationColor="#3abc98"
            paginationPosition="bottom-overlay" class="carousel-container" id="silomConnectQRCarousel">
            <slide v-for="(slide, index) in slides" :key="index" :id="`silomConnectQRSlide-${index}`">
              <h3 class="slide-title" :id="`silomConnectQRSlideTitle-${index}`">{{ slide.title }}</h3>
              <div class="slide-content">
                <img :src="slide.image" class="square-image"  :id="`silomConnectQRSlideContent-${index}`"/>
                <a :id="`silomConnectQRSlideButton-${index}`" v-show="slide.buttonLink" :href="slide.buttonLink" class="info-btn">
                  <img :src="slide.icon" class="line-btn-icon">
                  <span  :id="`silomConnectQRSlideButtonLabel-${index}`" style="vertical-align: middle; font-weight: lighter; color: white">
                    {{ slide.buttonLabel }}
                  </span>
                </a>
              </div>
            </slide>
          </Carousel>
        </div>
        <div v-else-if="mode === 'production'" class="text-center"  id="silomConnectQRModalProd">
          <Carousel :perPage="1" :autoplay="true" :loop="true" :adjustableHeight="true" paginationColor="#3abc98"
            paginationPosition="bottom-overlay" class="carousel-container" id="silomConnectQRCarouselProd">
            <slide  :id="`silomConnectQRSlideProd-${index}`" v-for="(slide, index) in slides" :key="index">
              <h3 class="slide-title" :id="`silomConnectQRSlideProdTitle-${index}`">{{ slide.title }}</h3>
              <div class="slide-content" :id="`silomConnectQRSlideProdContent-${index}`">
                <img :src="slide.image" class="square-image" :id="`silomConnectQRSlideProdImage-${index}`" />
                <a v-if="slide.buttonLink" :href="slide.buttonLink" class="info-btn" :id="`silomConnectQRSlideProdButton-${index}`">
                  <img :src="slide.icon" class="line-btn-icon" :id="`silomConnectQRSlideProdButtonIcon-${index}`">
                  <span style="vertical-align: middle; font-weight: lighter; color: white" :id="`silomConnectQRSlideProdButtonLabel-${index}`">
                    {{ slide.buttonLabel }}
                  </span>
                </a>
              </div>
            </slide>
          </Carousel>
        </div>
      </div>
      <template #footer>
        <div id="silomConnectQRModalFooter" class="mx-auto text-center">
          <p id="silomConnectQRModalFooterText">
            <input id="silomConnectQRModalFooterCheckbox" type="checkbox" @click="handlePopup" />
            {{ $t('donotShow') }}
          </p>
        </div>
      </template>
    </CModal>
  </CRow>
</template>


<script>
import { mapGetters } from 'vuex'
import axios from '../services/auth'
import moment from 'moment'
import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      popupModal: true,
      footer: '',
      body: '',
      close: false,
      authCode: '',
      expiredAt: '',
      date: moment(60 * 5 * 1000),
      isError: false,
      atClick: true,
      timerOn: '',
      mode: '',

    }
  },
  computed: {
    ...mapGetters(['shops', 'permissions']),
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    shop() {
      return this.shops.find((i) => i.objectId === this.shopObjectId)
    },
    time() {
      if (this.date.format('mm:ss') === '59:59') {
        this.expiredAt = ''
        this.authCode = ''
      } else {
        return this.date.format('mm:ss')
      }
    },
    selfOrderEnabled: {
      get() {
        const shop = this.shop || {};
        const delivery = shop.delivery || {};
        const isSelfOrderEnabled = delivery.isSelfOrderEnabled || false;
        const lock = this.permissions.selfOrder && this.permissions.selfOrder.lock;

        if (isSelfOrderEnabled === true && lock === false) {
          return true
        } else {
          return false
        }
      },
      set(value) {
        this.isSelfOrderEnabled = value;
      }
    },
    slides() {
      return [
      {
          title: this.$t('carousel.slideLineNotify.title'),
          image: '/img/LineNotifyAnouncment.PNG',
          buttonLabel: '',
          buttonLink: '',
          icon: ''
        },
        {
          title: this.$t('carousel.slideLineNotifyAds.title'),
          image: '/img/silomAlert/silomAlertAD.JPG',
          buttonLabel:  this.$t('carousel.slideLineNotifyAds.buttonLabel'),
          buttonLink: 'https://dashboard.silompos.com/setting/silom-alert/',
          icon: '/img/startup.png'
        },
        {
          title: this.$t('carousel.slide6.title'),
          image: '/img/New-Feature-2025.jpg',
          buttonLabel: '',
          buttonLink: '',
          icon: ''
        },
     
        {
          title: this.$t('carousel.slide5.title'),
          image: '/img/bigmenu.jpg',
          buttonLabel: '',
          buttonLink: '',
          icon: ''
        },
        {
          title: this.$t('carousel.slide1.title'),
          image: '/img/adsQR.jpg',
          buttonLabel: this.$t('carousel.slide1.buttonLabel'),
          buttonLink: 'https://liff.line.me/1645278921-kWRPP32q/?accountId=silompos',
          icon: '/img/question.png'
        },
      
       
        {
          title: this.$t('carousel.slide4.title'),
          image: '/img/qrLine.png',
          buttonLabel: this.$t('carousel.slide4.buttonLabel'),
          buttonLink: 'https://liff.line.me/1645278921-kWRPP32q/?accountId=silompos',
          icon: '/img/question.png'
        },
      ];
    }
  },
  mounted() {
    this.mode = process.env.NODE_ENV
  },
  methods: {
    handlePopup() {
      this.popupModal = false
      localStorage.setItem('silomconnect', 'true')
    },
    createAuthCode() {
      const idToken = `${localStorage.getItem('idToken')}`
      const uid = `${localStorage.getItem('shopsUid')}`
      axios.defaults.headers.common['refreshToken'] = idToken

      axios
        .post('/auth/v1.0/' + uid + '/authcode/create')
        .then((res) => {
          if (res.data.error.code === 0) {
            clearInterval(this.timerOn)
            this.authCode = res.data.data.authCode
            this.expiredAt = res.data.data.expired_at
            this.atClick = false
            this.countdown()
          } else {
            this.isError = true
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    countdown() {
      this.date = moment(60 * 5 * 1000)
      this.timerOn = setInterval(() => {
        this.date = moment(this.date.subtract(1, 'seconds'))
      }, 1000)
    },
  },
}
</script>
<style>
.line-btn {
  background-color: #00b900;
  width: 30%;
  display: block;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  margin-top: 3%;
  margin-left: 35%;
  margin-bottom: 10%;
}
</style>
<style scoped>
.modal-content {
    max-width: 90vw;
    max-height: 90vh; 
    overflow-y: auto; 
}
.carousel-container {
    max-width: 90vw; 
    max-height: 80vh; 
    overflow-y: auto;
}

.slide-content {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    text-align: center;
    height: 100%; 
}

.square-image {
    width: 380px;
    height: 380px;
    object-fit: cover; /* ทำให้รูปภาพเต็มพื้นที่สี่เหลี่ยมจตุรัส */
    border: #cdcdcd 1px solid;
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    margin-bottom: 15px; /* เพิ่มระยะห่างระหว่างรูปภาพกับปุ่ม */
}

.info-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: #29b289; /* สีพื้นหลังปุ่ม */
    border-radius: 5px;
    text-decoration: none;
    margin-top: 10px;
    margin-bottom: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}
.info-btn:hover {
    background-color: #29b289; /* เปลี่ยนสีพื้นหลังเมื่อ hover */
    color: #fff; /* เปลี่ยนสีของข้อความเมื่อ hover (ถ้าต้องการ) */
}
.line-btn-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px; /* เว้นระยะห่างระหว่างไอคอนและข้อความในปุ่ม */
}

.line-btn span {
    font-weight: lighter;
    color: white;
}

.slide-title {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}
@media (max-width: 450px) {
  .square-image {
    width: 280px; 
    height: 280px; 
    object-fit: cover;
    border-radius: 8px; 
    margin-bottom: 10px; 
  }

  .info-btn {
    padding: 8px 16px; 
    font-size: 14px; 
    margin-top: 8px;
    margin-bottom: 30px;
  }

  .line-btn-icon {
    width: 16px; 
    height: 16px;
    margin-right: 4px;
  }

  .slide-title {
    font-size: 20px; 
    margin-bottom: 8px; 
  }

  .carousel-container {
    max-width: 95vw; 
    max-height: 85vh;
  }
}

</style>

